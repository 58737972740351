import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

interface StyledProps {
  width?: string | number
  bottom?: string | number
}

const StyledImageLink = styled.div<StyledProps>`
  position: relative;
  .image__link__img {
    width: ${(props) => (props.width ? `${props.width}px` : "280px")};
    z-index: -1;
    margin: 0 auto ${(props) => (props.bottom ? `-${props.bottom}px` : "-30px")};
    min-height: 200px;
  }
`

interface Props {
  copy: string
  image?: string
  width?: string | number
  bottom?: string | number
  to: string
}

export default function ImageLink({ copy, image, width, bottom, to }: Props) {
  const ref = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ref?.current,
        start: "bottom bottom",
      },
    })

    tl.from(ref.current, { y: 50, opacity: 0 })
  }, [])

  return (
    <StyledImageLink ref={ref} width={width} bottom={bottom}>
      {image && (
        <div className="image__link__img">
          <img alt={copy} src={image} />
        </div>
      )}
      {to !== "#" && (
        <>
          {to?.includes("http") ? (
            <a href={to} className="button-home">
              {copy}
            </a>
          ) : (
            <Link to={to} className="button-home">
              {copy}
            </Link>
          )}
        </>
      )}
    </StyledImageLink>
  )
}
