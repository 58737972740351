import React from "react"
import styled from "styled-components"

const StyledHeader = styled.header`
  width: 100%;
  height: 55px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  a {
    img {
      display: block;
      width: 200px;
    }
  }
`

export default function Header() {
  return (
    <>
      <StyledHeader>
        <a href="https://www.penhaligons.com/uk/en" title="Penhaligon's">
          <img
            src="/alternative-penhaligons-logo.svg"
            alt="Penhaligon's logo"
          />
        </a>
      </StyledHeader>
    </>
  )
}
