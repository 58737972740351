import React from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/all"
import homeBackgroundImg from "../../assets/home-background-parallax.jpg"
import homeTreesImg from "../../assets/home-background-trees.png"
import homeBallonsImg from "../../assets/home-balloon.png"
import pipingLeftImg from "../../assets/piping-left.png"
import pipingRightImg from "../../assets/piping-right.png"
import treesImg from "../../assets/trees.png"
import snowImg from "../../assets/snow.png"
import rollupImg from "../../assets/rollup.png"
import bottomLogoImg from "../../assets/bottom-logo.png"
import hulaGif from "../../assets/hula.gif"
import cannonGif from "../../assets/cannon.gif"
import jugglingGif from "../../assets/juggling.gif"
import Header from "./Header"

gsap.registerPlugin(ScrollTrigger)

const StyledHomeLayout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: calc(var(--vh) * 100);
  overflow: hidden;
  color: var(--black);
  .home__background__container {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
  }
  .home__background {
    position: relative;
    height: 100%;
    z-index: -1;
    .home__gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 65%;
      background: linear-gradient(
        to bottom,
        rgb(227, 237, 244, 0) 0%,
        rgb(227, 237, 244, 1) 30%,
        rgb(227, 237, 244, 1) 100%
      );
      @media (max-width: 500px) {
        height: 90%;
      }
    }
    .home__background__img {
      object-fit: cover;
      min-width: 1200px;
      @media (max-width: 500px) {
        min-width: 1000px;
      }
    }
    .home__background__img-trees {
      position: absolute;
      top: 0;
      left: 0;
    }
    .home__background__img-balloons {
      position: absolute;
      top: 0;
      left: 0;
    }
    .home__gif {
      position: absolute;
      width: 200px;
      @media (max-width: 500px) {
        display: none;
      }
      &.home__gif-hula {
        top: 1300px;
        left: 65%;
      }
      &.home__gif-cannon {
        top: 400px;
        left: 62%;
      }
      &.home__gif-juggling {
        top: 380px;
        left: 20%;
      }
    }
  }
  .home__shadow {
    position: relative;
    min-height: 100%;
    flex: 1;
    :after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.6) 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &.home__shadow-right {
      :after {
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.6) 30%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
  .home__content-1 {
    max-width: 375px;
    margin: 415px auto 0;
    padding: 0 20px;
    @media (max-width: 500px) {
      margin: 345px auto 0;
    }
  }
  .home__trees {
    margin-top: -300px;
    height: 1000px;
    pointer-events: none;
    img {
      min-width: 1200px;
    }
  }
  .home__snow {
    position: relative;
    margin-top: -500px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    :after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        to bottom,
        rgb(227, 237, 244, 0) 0%,
        rgb(227, 237, 244, 0) 50%,
        rgb(227, 237, 244, 0.5) 100%
      );
    }
    .home__rollup {
      position: absolute;
      top: 175px;
      left: 50%;
      transform: translate(-50%, 0);
      @media (max-width: 500px) {
        width: 300px;
      }
    }
  }
  .home__content-2 {
    position: relative;
    width: 1200px;
    margin-top: -220px;
    z-index: 2;
    .home__content__background {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
        to bottom,
        rgb(227, 237, 244, 0.5) 0%,
        rgb(227, 237, 244, 1) 100px
      );
      z-index: -1;
    }
    .home__bottom__logo {
      margin: auto;
      padding: 20px;
      width: 90vw;
      max-width: 300px;
    }
  }
`

const StyledPipingBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(var(--vh) * 100);
  display: grid;
  grid-template-columns: 1fr 1200px 1fr;
  pointer-events: none;
  z-index: 3;
  @media (max-width: 1200px) {
    grid-template-columns: 0fr 1fr 0fr;
  }
  .home__piping__container {
    display: flex;
    justify-content: space-between;
  }
`

interface Props {
  map?: React.ReactNode
  grid?: React.ReactNode
}

export default function HomeLayout({ map, grid }: Props) {
  React.useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".home__background__img-trees",
        start: "top middle",
        end: "bottom top",
        scrub: 1,
        // markers: process.env.NODE_ENV === "development",
      },
    })

    const tlTwo = gsap.timeline({
      scrollTrigger: {
        start: "top middle",
        end: "bottom top",
        scrub: 1,
        // markers: process.env.NODE_ENV === "development",
      },
    })

    const tlThree = gsap.timeline({
      scrollTrigger: {
        trigger: ".home__background",
        start: "top top",
        end: "500px",
        scrub: 1,
        // markers: process.env.NODE_ENV === "development",
      },
    })

    tl.fromTo(
      ".home__background__img-trees",
      { y: 150 },
      { y: -50, ease: "none" }
    )

    tlTwo.fromTo(".home__trees", { y: 20 }, { y: -100, ease: "none" })

    tlThree.fromTo(
      ".home__background__img-balloons",
      { y: -25 },
      { y: 75, ease: "none" }
    )
  }, [])

  return (
    <>
      <Header />
      <StyledHomeLayout>
        <div className="home__background__container">
          <div className="home__shadow" />
          <div className="home__background">
            <img
              alt=""
              src={homeBackgroundImg}
              className="home__background__img"
            />
            <div className="home__gradient" />
            <img
              alt=""
              src={homeBallonsImg}
              className="home__background__img home__background__img-balloons"
            />
            <img
              alt=""
              src={homeTreesImg}
              className="home__background__img home__background__img-trees"
            />
            <img alt="" src={hulaGif} className="home__gif home__gif-hula" />
            <img
              alt=""
              src={cannonGif}
              className="home__gif home__gif-cannon"
            />
            <img
              alt=""
              src={jugglingGif}
              className="home__gif home__gif-juggling"
            />
          </div>
          <div className="home__shadow home__shadow-right" />
        </div>
        <div className="home__content-1">{map}</div>
        <div className="home__bottom__decor">
          <div className="home__trees">
            <img alt="" src={treesImg} />
          </div>
          <div className="home__snow">
            <img alt="" src={snowImg} />
            <img
              alt="Roll up, roll up"
              src={rollupImg}
              className="home__rollup"
            />
          </div>
        </div>
        <div className="home__content-2">
          <div className="home__content__background" />
          <div className="home__grid">{grid}</div>
          <img
            alt="The greatest show in the North Pole"
            src={bottomLogoImg}
            className="home__bottom__logo"
          />
        </div>
        <StyledPipingBorder>
          <div />
          <div className="home__piping__container">
            <div className="home__piping home__piping-left">
              <img alt="" src={pipingLeftImg} />
              <img alt="" src={pipingLeftImg} />
            </div>
            <div className="home__piping home__piping-right">
              <img alt="" src={pipingRightImg} />
              <img alt="" src={pipingRightImg} />
            </div>
          </div>
          <div />
        </StyledPipingBorder>
      </StyledHomeLayout>
    </>
  )
}
